<template>
  <b-modal
    :title="$gettext('Create application')"
    :cancel-title="$gettext('Cancel')"
    @ok="handleCreateApplication"
    id="create-application-modal"
    hide-header-close
  >
    <form @submit.prevent="handleCreateApplication">
      <FormGroupMultiselectPaginate
        :label="$gettext('Candidate')"
        :description="$gettext('Add new candidates in the candidates section')"
        field="candidate_id"
        store="candidates"
        option-label="full_name"
        description-label="email"
        track-by="id"
        v-model="applicationSelectedCandidate"
        :fetch-params="fetchParams"
        required
      />

      <FormGroupFile
        :label="$gettext('Resume')"
        field="resume"
        v-model="applicationPayload.resume"
        required
      />
      <FormGroupFile
        :label="$gettext('Cover letter')"
        field="cover_letter"
        v-model="applicationPayload.cover_letter"
      />
    </form>
  </b-modal>
</template>
<script>
import FormGroupMultiselectPaginate from "@/components/FormGroups/FormGroupMultiselectPaginate";
import FormGroupFile from "@/components/FormGroups/FormGroupFile";
import { mapActions } from "vuex";
export default {
  components: { FormGroupMultiselectPaginate, FormGroupFile },
  props: {
    offerId: {
      type: [String, Number],
      required: true
    },
    alreadyAppliedCandidateIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      applicationSelectedCandidate: {},
      applicationPayload: {
        resume: null,
        cover_letter: null,
        candidate_id: null
      }
    };
  },
  computed: {
    fetchParams() {
      return {
        offer_id: this.offerId
      };
    }
  },
  methods: {
    ...mapActions("offerApplications", {
      fetchApplications: "paginate"
    }),
    ...mapActions("candidates", ["apply"]),
    ...mapActions("form", ["submit"]),
    handleCreateApplication(event) {
      event.preventDefault();
      const payload = {
        ...this.applicationPayload,
        offer: this.offerId,
        candidate_id: this.applicationSelectedCandidate.id ?? null
      };
      const form = Object.keys(payload).reduce((form, index) => {
        if (payload[index] !== null) {
          form.append(index, payload[index]);
        }
        return form;
      }, new FormData());
      this.submit(() => this.apply(form)).then(() => {
        this.fetchApplications().then(() => {
          this.applicationPayload = {
            ...this.applicationPayload,
            resume: null,
            cover_letter: null,
            candidate_id: null
          };
          this.applicationSelectedCandidate = {};
          this.$bvModal.hide("create-application-modal");
        });
      });
    }
  }
};
</script>
