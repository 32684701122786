<template>
  <div class="card">
    <div class="card-body">
      <CreateApplicationModal v-if="offerId" :offer-id="offerId" />

      <EmptyState
        icon="jbsmd-candidate"
        v-if="showEmptyState"
        :to="{ name: emptyStateRoute }"
        :message="emptyStateText"
        :custom-button-text="emptyStateButtonText"
      >
        <div class="text-center m-lg-5">
          <hr class="mb-5" />
          <div class="w-50 mx-auto my-3">
            <translate>You can also add a candidate manually.</translate>
          </div>
          <b-button variant="primary" @click="openCreateApplicationModal"
            ><translate>Add candidate</translate></b-button
          >
        </div>
      </EmptyState>
      <div v-else>
        <div
          class="alert alert-warning"
          v-if="showCandidateLimitReachedWarning"
        >
          <p class="mb-1">{{ candidateLimitReachedWarningText }}</p>
          <router-link
            :to="{ name: 'subscription' }"
            class="btn btn-warning btn-sm"
            ><translate>Increase your candidate limit</translate></router-link
          >
        </div>
        <div class="row align-items-center mb-3">
          <div class="col-md-8 col-xl-9">
            <ul
              class="list-group list-group-horizontal d-none d-md-flex application-status-list"
            >
              <li
                v-for="(status, index) in applicationStatusList"
                :key="index"
                class="list-group-item border-0 cursor-pointer"
                :class="[
                  index === currentStatus ? 'text-primary' : 'text-muted',
                  applicationsCountByStatus[index] === 0
                    ? 'd-none d-xl-inline'
                    : ''
                ]"
                @click="changeStatus(index)"
              >
                {{ status }}
                <span
                  class="badge mb-0"
                  :class="[
                    index === currentStatus ? 'bg-primary' : 'bg-secondary'
                  ]"
                  >{{ applicationsCountByStatus[index] }}</span
                >
              </li>
            </ul>
          </div>
          <div class="col-md-4 col-xl-3">
            <div
              class="btn float-right btn-block btn-lg"
              :class="[showAdvanceFilter ? '' : 'btn-primary']"
              @click="toggleShowAdvanceFilter"
            >
              <i class="fas fa-filter mr-2"></i>
              <translate>Advanced Filters</translate>
              <span
                class="badge badge-danger"
                v-if="hasFilters && !showAdvanceFilter"
              >
                {{ filters.length }}
              </span>
            </div>
          </div>
        </div>

        <AdvancedFilters
          v-show="showAdvanceFilter"
          @filter-applied="handleFilterApplied"
          store="offerApplications"
          :should-fetch-schema="true"
          class="mb-3"
        ></AdvancedFilters>

        <div class="row">
          <div class="col-md-3">
            <b-button
              block
              variant="secondary"
              @click="openCreateApplicationModal"
              class="mt-0"
              size="lg"
              ><translate>Add candidate</translate></b-button
            >
            <hr />
            <ApplicationCandidateList
              :offer-id="offerId"
              @pageChange="
                navigateToFirstApplication(offerId, firstApplication.id)
              "
            />
          </div>
          <div class="col-md-9">
            <content-loader v-if="showLoader('offer-application')" />
            <router-view
              @statusChanged="handleStatusChange"
              v-show="!showLoader('offer-application')"
            ></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import EmptyState from "@/components/EmptyState";
import CreateApplicationModal from "@/components/Offers/CreateApplicationModal";
import ApplicationCandidateList from "@/components/Offers/ApplicationCandidateList";
import { applicationStatus } from "@/enums/applicationStatus";
import ContentLoader from "@/layouts/components/ContentLoader";
import { mapObject } from "@/utils/object";
import AdvancedFilters from "@/components/AdvancedFilters";

export default {
  components: {
    AdvancedFilters,
    CreateApplicationModal,
    EmptyState,
    ApplicationCandidateList,
    ContentLoader
  },
  props: {
    offerId: {
      type: [String, Number],
      require: true
    },
    applicationId: {
      type: [String, Number],
      require: false
    }
  },
  async created() {
    await this.setOfferId(this.offerId);
    await this.fetchData(this.offerId);
    if (!this.applicationId && this.hasApplications) {
      this.navigateToFirstApplication(this.offerId, this.firstApplication.id);
    }
  },
  data() {
    return {
      currentStatus: "all",
      showAdvanceFilter: false
    };
  },
  computed: {
    ...mapGetters("offers", { offer: "item", stats: "stats" }),
    ...mapGetters("offerApplications", {
      applications: "items",
      hasApplications: "hasItems",
      firstApplication: "firstItem",
      currentPage: "currentPage",
      filters: "filters"
    }),
    ...mapGetters("app", ["showLoader"]),
    hasFilters() {
      return this.filters.length;
    },
    hasVisibleApplication() {
      return this.stats.visible > 0;
    },
    showEmptyState() {
      return !this.hasVisibleApplication;
    },
    showCandidateLimitReachedWarning() {
      return this.hasVisibleApplication && this.candidatesLimitExceeded;
    },
    emptyStateRoute() {
      return this.candidatesLimitExceeded ? "subscription" : "publications";
    },
    emptyApplications() {
      return this.stats.visible === 0;
    },
    candidatesLimitExceeded() {
      return (this.stats?.total || 0) > (this.stats?.visible || 0);
    },
    applicationStatusList() {
      return { all: this.$gettext("All"), ...applicationStatus };
    },
    emptyStateText() {
      if (this.candidatesLimitExceeded) {
        const translated = this.$gettext(
          "You can't see any of the %{ total } applications since you have reached your candidate limit."
        );
        return this.$gettextInterpolate(translated, {
          total: this.stats.total
        });
      } else {
        return this.$gettext(
          "You don't have any applications yet for this job offer. Publish your offer on more platforms to increase your reach and attract more candidates."
        );
      }
    },
    candidateLimitReachedWarningText() {
      const translated = this.$gettext(
        "You only see %{ shown } of %{ total } applications since you have reached your candidate limit."
      );
      return this.$gettextInterpolate(translated, {
        shown: this.stats.visible,
        total: this.stats.total
      });
    },
    applicationsCountByStatus() {
      return mapObject(this.applicationStatusList, (value, status) => {
        return status === "all"
          ? this.stats.total
          : this.stats.totalByStatus[status] || 0;
      });
    },
    emptyStateButtonText() {
      return this.candidatesLimitExceeded
        ? this.$gettext("Increase your candidate limit")
        : this.$gettext("Publish your offer");
    }
  },
  methods: {
    ...mapActions("offerApplications", ["paginate", "fetchSchema"]),
    ...mapMutations("offerApplications", [
      "setOfferId",
      "setStatusFilter",
      "setSearch",
      "setCurrentPage"
    ]),
    ...mapMutations("app", ["startLoader", "stopLoader"]),
    handleFilterApplied() {
      if (this.hasApplications) {
        this.navigateToFirstApplication(this.offerId, this.firstApplication.id);
      } else {
        this.$router.push({
          name: "offer-applications",
          params: {
            offerId: this.offerId
          }
        });
      }
    },
    async handleStatusChange() {
      if (this.currentStatus !== "all") {
        if (this.applications.length === 1) {
          this.setCurrentPage(this.currentPage - 1);
        }
        await this.paginate();
        if (this.hasApplications) {
          this.navigateToFirstApplication(
            this.offerId,
            this.firstApplication.id
          );
        } else {
          await this.$router.push({
            name: "offer-applications",
            params: {
              offerId: this.offerId
            }
          });
        }
      }
    },
    async fetchData(offerId) {
      this.startLoader("offer-tab");
      await this.setOfferId(offerId);
      await this.fetchSchema();
      this.setSearch("");

      await this.setStatusFilter(this.currentStatus);
      await this.paginate();
      this.stopLoader("offer-tab");
    },
    navigateToFirstApplication(offerId, applicationId) {
      this.$router.push({
        name: "application-candidate",
        params: {
          offerId: offerId,
          applicationId: applicationId
        }
      });
    },
    async changeStatus(status) {
      this.currentStatus = status;
      this.setCurrentPage(1);
      this.setStatusFilter(status);
      await this.paginate();
      if (this.firstApplication) {
        this.navigateToFirstApplication(this.offerId, this.firstApplication.id);
      }
    },
    openCreateApplicationModal() {
      this.$bvModal.show("create-application-modal");
    },
    toggleShowAdvanceFilter() {
      this.showAdvanceFilter = !this.showAdvanceFilter;
    }
  },
  watch: {
    "$route.params": {
      async handler(current, previous) {
        if (parseInt(current.offerId) !== parseInt(previous.offerId)) {
          this.setCurrentPage(1);
          await this.fetchData(current.offerId);
          if (!current.applicationId && this.hasApplications) {
            this.navigateToFirstApplication(
              current.offerId,
              this.firstApplication.id
            );
          }
        }
      }
    }
  }
};
</script>
