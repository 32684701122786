<template>
  <div>
    <b-form-input
      class="mb-2 py-3"
      v-model="search"
      :placeholder="$gettext('Search candidate...')"
      @input="handleSearch"
    ></b-form-input>
    <div class="alert alert-warning" v-show="noSearchResults">
      <translate>Sorry, no candidates found!</translate>
    </div>
    <ul class="list-group application-candidates">
      <li
        v-for="(application, index) in applications"
        :key="index"
        class="list-group-item"
      >
        <router-link
          :to="{
            name: 'application-candidate',
            params: {
              applicationId: application.id
            }
          }"
        >
          {{ application.candidate.first_name }}
          {{ application.candidate.last_name }}
        </router-link>
      </li>
    </ul>

    <b-pagination
      v-if="showPagination"
      v-model="currentPage"
      :per-page="10"
      :total-rows="total"
      @change="handlePageChange"
      align="center"
      class="mt-4 mb-5"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  props: {
    offerId: {
      required: true,
      type: [Number, String]
    }
  },
  computed: {
    ...mapGetters("offerApplications", {
      applications: "items",
      total: "total"
    }),
    noSearchResults() {
      return this.search.length > 0 && this.applications.length === 0;
    },
    showPagination() {
      return this.total > this.applications.length;
    },
    currentPage: {
      get() {
        return this.$store.getters["offerApplications/currentPage"];
      },
      set(value) {
        this.$store.commit("offerApplications/setCurrentPage", value);
      }
    },
    search: {
      get() {
        return this.$store.getters["offerApplications/search"];
      },
      set(value) {
        this.$store.commit("offerApplications/setSearch", value);
      }
    }
  },
  methods: {
    ...mapActions("offerApplications", ["applySearch", "paginate"]),
    ...mapMutations("offerApplications", ["setSearch"]),
    handleSearch(search) {
      this.applySearch(search);
    },
    async handlePageChange(page) {
      this.$store.commit("offerApplications/setCurrentPage", page);
      await this.paginate();
      this.$emit("pageChange");
    }
  },
  watch: {
    offerId: {
      handler() {
        this.search = "";
      }
    }
  }
};
</script>
