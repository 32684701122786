var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[(_vm.offerId)?_c('CreateApplicationModal',{attrs:{"offer-id":_vm.offerId}}):_vm._e(),(_vm.showEmptyState)?_c('EmptyState',{attrs:{"icon":"jbsmd-candidate","to":{ name: _vm.emptyStateRoute },"message":_vm.emptyStateText,"custom-button-text":_vm.emptyStateButtonText}},[_c('div',{staticClass:"text-center m-lg-5"},[_c('hr',{staticClass:"mb-5"}),_c('div',{staticClass:"w-50 mx-auto my-3"},[_c('translate',[_vm._v("You can also add a candidate manually.")])],1),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.openCreateApplicationModal}},[_c('translate',[_vm._v("Add candidate")])],1)],1)]):_c('div',[(_vm.showCandidateLimitReachedWarning)?_c('div',{staticClass:"alert alert-warning"},[_c('p',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.candidateLimitReachedWarningText))]),_c('router-link',{staticClass:"btn btn-warning btn-sm",attrs:{"to":{ name: 'subscription' }}},[_c('translate',[_vm._v("Increase your candidate limit")])],1)],1):_vm._e(),_c('div',{staticClass:"row align-items-center mb-3"},[_c('div',{staticClass:"col-md-8 col-xl-9"},[_c('ul',{staticClass:"list-group list-group-horizontal d-none d-md-flex application-status-list"},_vm._l((_vm.applicationStatusList),function(status,index){return _c('li',{key:index,staticClass:"list-group-item border-0 cursor-pointer",class:[
                index === _vm.currentStatus ? 'text-primary' : 'text-muted',
                _vm.applicationsCountByStatus[index] === 0
                  ? 'd-none d-xl-inline'
                  : ''
              ],on:{"click":function($event){return _vm.changeStatus(index)}}},[_vm._v(" "+_vm._s(status)+" "),_c('span',{staticClass:"badge mb-0",class:[
                  index === _vm.currentStatus ? 'bg-primary' : 'bg-secondary'
                ]},[_vm._v(_vm._s(_vm.applicationsCountByStatus[index]))])])}),0)]),_c('div',{staticClass:"col-md-4 col-xl-3"},[_c('div',{staticClass:"btn float-right btn-block btn-lg",class:[_vm.showAdvanceFilter ? '' : 'btn-primary'],on:{"click":_vm.toggleShowAdvanceFilter}},[_c('i',{staticClass:"fas fa-filter mr-2"}),_c('translate',[_vm._v("Advanced Filters")]),(_vm.hasFilters && !_vm.showAdvanceFilter)?_c('span',{staticClass:"badge badge-danger"},[_vm._v(" "+_vm._s(_vm.filters.length)+" ")]):_vm._e()],1)])]),_c('AdvancedFilters',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAdvanceFilter),expression:"showAdvanceFilter"}],staticClass:"mb-3",attrs:{"store":"offerApplications","should-fetch-schema":true},on:{"filter-applied":_vm.handleFilterApplied}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('b-button',{staticClass:"mt-0",attrs:{"block":"","variant":"secondary","size":"lg"},on:{"click":_vm.openCreateApplicationModal}},[_c('translate',[_vm._v("Add candidate")])],1),_c('hr'),_c('ApplicationCandidateList',{attrs:{"offer-id":_vm.offerId},on:{"pageChange":function($event){return _vm.navigateToFirstApplication(_vm.offerId, _vm.firstApplication.id)}}})],1),_c('div',{staticClass:"col-md-9"},[(_vm.showLoader('offer-application'))?_c('content-loader'):_vm._e(),_c('router-view',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showLoader('offer-application')),expression:"!showLoader('offer-application')"}],on:{"statusChanged":_vm.handleStatusChange}})],1)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }